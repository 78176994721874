#root, html, body {
  height: 100%;
  width: 100%;
}

.center-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.react-form-builder, .react-form-builder > :first-child {
  display: flex;
}

.react-form-builder > div > .react-form-builder-preview {
  width: 75%;
}
.react-form-builder > div > .react-form-builder-toolbar {
  width: 25%;
}

/**
Display the header on hover
*/
.header-on-hover-display .card-header {
  display: none;
}
.header-on-hover-display:hover > .card-header {
  display: block;
}
.header-on-hover-display:hover {
  border-style: dashed;
  border-width: 1px;
}

.builder-identify-border {
  border-style: dashed;
  border-width: 0.3px;
  border-color: lightgray;
}

/**
popover, increasing width as they are really small
*/
.popover {
  max-width: 500px;
}